<template>
<div class="flex justify-start">
    <n-button
        secondary
        class="rounded-3xl"
        @click="router.go(-1)">
        <n-icon
            size="14"
            class="text-gray-600 dark:text-white/75 mr-2">
            <ArrowLeft12Regular />
        </n-icon>
        {{ t('back') }}
    </n-button>
</div>

<n-alert v-if="bots.localization['bots_how_to_create_bot']" type="info" class="rounded-lg mt-4">
    <template #header>
        <div class="notes text-xs pt-0 -mt-1" v-html="bots.localization['bots_how_to_create_bot']"></div>
    </template>
</n-alert>

<section v-if="init" class="pb-16">
    <n-alert v-if="isExchangeFutures" class="mt-4 rounded-lg" type="warning">
        <div class="notes text-xs" v-html="bots.localization['bots/simulate_mode/not_available']"></div>
    </n-alert>

    <section class="flex items-stretch mt-4">
        <div class="w-4/12 pr-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <!-- simulate -->
                    <rb-checkbox
                        v-if="!isExchangeFutures"
                        class="mb-4"
                        :help-warning="dataForm.settings.simulate ? bots.localization['bots_simulation_notes'] : null"
                        :label="'- ' + bots.localization['simulate_button']"
                        v-model:checked="dataForm.settings.simulate" />

                    <div v-if="dataForm.settings.simulate" class="mt-4">
                        <n-alert class="rounded-lg" type="warning">
                            <div
                                class="notes text-xs"
                                v-html="bots.localization['run_simulation_after_specified_date_info'] || 'Вы можете задать момент старта (дата и время) работы бота в режиме симуляции по имеющимся данным изменения цены торговой пары в прошлом. По умолчанию симуляция проводится за последние 60 дней.'">
                            </div>
                        </n-alert>

                        <section class="mt-2">
                            <rb-checkbox
                                class="mt-2"
                                :label="bots.localization['run_simulation_after_specified_date'] || 'Запустить симуляцию после заданной даты'"
                                :checked="!!~dataForm.start_filters.findIndex(({ id }) => id == 'simulate_start_time:check:time')"
                                @click="triggerFilter('simulate_start_time:check:time')" />

                            <template v-if="!!~dataForm.start_filters.findIndex(({ id }) => id == 'simulate_start_time:check:time')">
                                <n-popover
                                    v-model:show="showStartTime"
                                    class="rounded-md"
                                    placement="bottom-end"
                                    trigger="click">
                                    <template #trigger>
                                        <n-button
                                            secondary
                                            type="default"
                                            class="rounded-md flex items-center px-3 mt-4"
                                            @click="showStartTime = true">
                                            <div class="w-full flex justify-between items-center font-semibold">
                                                <span class="mr-2">
                                                    {{ dataForm.start_filters[hasFilter('simulate_start_time:check:time')].value }}
                                                </span>
                                                <n-icon size="20">
                                                    <CalendarLtr32Regular />
                                                </n-icon>
                                            </div>
                                        </n-button>
                                    </template>
                                    <section class="flex">
                                        <n-date-picker
                                            panel
                                            class="mt-2"
                                            type="datetime"
                                            size="large"
                                            :actions="null"
                                            :is-date-disabled="dateDisabled"
                                            :value="new Date(dataForm.start_filters[hasFilter('simulate_start_time:check:time')].value).getTime()"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @update:value="setStartTime(hasFilter('simulate_start_time:check:time'), $event)" />
                                    </section>
                                </n-popover>
                            </template>
                        </section>
                        <n-divider />
                    </div>
                    
                    <!-- name -->
                    <rb-input
                        id="name"
                        class="mb-4"
                        :label="dataForm.name.title"
                        :help="dataForm.name.dataHelp"
                        :status="!!bots.errorsForm?.name ? 'error' : undefined"
                        :msg="bots.errorsForm?.name?.msg"
                        :placeholder="dataForm.name.placeholder"
                        v-model:value="dataForm.name.value"
                        @update:value="delete bots.errorsForm.name" />

                    <!-- exchange -->
                    <div class="flex justify-between items-end">
                        <rb-select
                            filterable
                            class="flex-grow"
                            :help="dataForm.exchange.dataHelp"
                            :help-warning="exchangeMeta3Info ? exchangeMeta3Info : null"
                            :label="dataForm.exchange.title"
                            :options="exchangesSorted"
                            :loading="dataForm.exchange.loading"
                            :status="dataForm.exchange.status === 'error' ? 'error' : undefined"
                            :msg="dataForm.exchange.msg"
                            v-model:value="dataForm.exchange.value"
                            @update:value="dataForm.exchange.status = null, dataForm.exchange.msg = null" />
                        <span
                            v-if="dataForm.exchange.value !== -1"
                            class="inline-block rb-td-stock-icon ml-4 w-16 h-8"
                            :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`">
                        </span>
                    </div>

                    <!-- api-key -->
                    <template v-if="dataForm.api_key.show">
                        <n-divider />
                        <api-keys
                            id="api_key"
                            mode="bots"
                            :help="dataForm.api_key.dataHelp"
                            :apiKeysRefs="apiKeysRefs"
                            :mainTitle="dataForm.api_key.title"
                            :loading="dataForm.exchange.loading"
                            :exchange="dataForm.exchange"
                            :status="!!bots.errorsForm?.api_key ? 'error' : undefined"
                            :msg="bots.errorsForm?.api_key?.msg"
                            v-model="dataForm.api_key.value"
                            @update:modelValue="delete bots.errorsForm.api_key"
                            @getApiKeysList="getApiKeysList" />
                    </template>

                    <!-- pair -->
                    <template v-if="dataForm.pair.show">
                        <n-divider />
                        <rb-pairs
                            id="pair"
                            :pairsRefs="pairsRefs"
                            :loading="dataForm.pair.loading"
                            :recommendedPairsRef="recommendedPairsRef"
                            :tickerMessage="tickerMessage"
                            :label="dataForm.pair.label"
                            :title="dataForm.pair.title"
                            :dataHelp="dataForm.pair.dataHelp"
                            :howToChooseInfo="dataForm.pair.howToChooseInfo"
                            :gridNavLabel="dataForm.pair.gridNavLabel"
                            :recommendedPairsInfo="dataForm.pair.recommendedPairsInfo"
                            :status="!!bots.errorsForm?.pair ? 'error' : undefined"
                            :msg="bots.errorsForm?.pair?.msg"
                            v-model="dataForm.pair.value"
                            @update:modelValue="delete bots.errorsForm.pair" />
                    </template>

                    <!-- algo -->
                    <template v-if="dataForm.algo.show">
                        <n-divider />
                        <rb-select
                            id="algo"
                            :label="dataForm.algo.title"
                            :options="algoOptions"
                            :help="dataForm.algo.dataHelp"
                            :status="!!bots.errorsForm?.algo ? 'error' : undefined"
                            :msg="bots.errorsForm?.algo?.msg"
                            v-model:value="dataForm.algo.value"
                            @update:modelValue="delete bots.errorsForm.algo" />
                    </template>
                </template>
            </n-card>
        </div>

        <Transition name="slide-fade">
            <div v-if="dataForm.wallet.show" class="w-4/12 px-2 transition-all">
                <n-card
                    size="small"
                    :bordered="false"
                    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                    :segmented="{
                        content: true,
                        footer: 'soft'
                    }">
                    <template #default>
                        <!-- wallet -->
                        <bot-wallet
                            id="wallet"
                            :data="dataForm.wallet"
                            :apiKeysRefs="apiKeysRefs"
                            :coinSymbolForWallet="coinSymbolForWallet"
                            :exchangeInfoRef="exchangeInfo"
                            :exchangeRef="dataForm.exchange.value"
                            :pairWalletsRefs="pairWalletsRefs"
                            :api-key="dataForm.api_key.value"
                            :status="!!bots.errorsForm?.wallet ? 'error' : undefined"
                            :msg="bots.errorsForm?.wallet?.msg"
                            v-model="dataForm.wallet.value"
                            @walletReady="walletReady($event)"
                            @walletUpdate="walletUpdate($event)"
                            @update:modelValue="delete bots.errorsForm.wallet" />

                        <!-- shuolder -->
                        <template v-if="isExchangeFutures && isWalletSelected">
                            <n-divider />
                            <rb-shoulder
                                :help-warning="dataForm.leverage.info"
                                :pair="leverageMinMax"
                                :disabled="!isWalletSelected"
                                :label="dataForm.leverage.label"
                                v-model="dataForm.leverage.value">
                                <template #messages>
                                    <div v-if="dataForm.leverage.status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ dataForm.leverage.msg }}</div>
                                </template>
                            </rb-shoulder>
                        </template>

                        <!-- positionmode -->
                        <template v-if="isExchangeFutures && isWalletSelected">
                            <n-divider />
                            <bot-positionmode/>
                        </template>

                        <!-- wallet commissions -->
                        <template v-if="isWalletSelected && exchangeCommissionCoin && !dataForm.settings.simulate">
                            <n-divider />
                            <bot-wallet-commissions
                                :isUpdating="isUpdating"
                                :data="dataForm.comm_type"
                                :dataForm="dataForm"
                                :exchangeCommissionCoin="exchangeCommissionCoin"
                                v-model="dataForm.comm_type.value" />
                        </template>
                    </template>
                </n-card>
            </div>
        </Transition>
        <Transition name="slide-fade">
            <div v-if="isWalletSelected" class="w-4/12 pl-2 transition-all">
                <n-card
                    size="small"
                    :bordered="false"
                    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                    :segmented="{
                        content: true,
                        footer: 'soft'
                    }">
                    <template #default>
                        <!-- presets -->
                        <bot-presets/>
                    </template>
                </n-card>
            </div>
        </Transition>
    </section>

    <Transition name="slide-fade">
        <div v-if="isWalletSelected" class="transition-all pt-4 mb-16">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <!-- bot settings -->
                    <bot-settings
                        :exchangeInfoRef="exchangeInfo" />
                </template>
            </n-card>

            <!-- Additional Settings -->
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-additional-settings
                        class="mt-4"
                        :walletsRefs="walletsRefs"/>
                </template>
            </n-card>

            <!-- Stop-Loss -->
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-stop-loss
                        class="mt-4"
                        :pairsRefs="pairsRefs" />
                </template>
            </n-card>

            <!-- Auto Inversion -->
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-auto-inversion
                        class="mt-4"
                        :dataForm="dataForm"
                        @update:algo_autoinversion_enabled="dataForm.algo_autoinversion_enabled = $event"
                        @update:algo_autoinversion_bot_id="dataForm.algo_autoinversion_bot_id = $event" />
                </template>
            </n-card>

            <!-- Switch Trading Pair -->
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-switch-trading-pair
                        class="mt-4"
                        :isExchangeCoin="isExchangeCoin"
                        :symbolsForMarketRefs="symbolsForMarketRefs" />
                </template>
            </n-card>

            <!-- Bot Startn Filters -->
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-start-filters
                        class="mt-4"
                        :pairsRefs="pairsRefs" />
                </template>
            </n-card>

            <!-- Bot Notifications -->
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-notifications class="mt-4" />
                </template>
            </n-card>

            <!-- Bot Extended Settings -->
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <bot-extended-settings class="mt-4" />
                </template>
            </n-card>
        </div>
    </Transition>

    <div class="fixed w-full left-0 bottom-0 bg-gray-100 dark:bg-darkbg py-4 z-10 px-12">
        <div class="flex justify-end">
            <n-button
                strong
                size="large"
                class="rounded-md text-white/90 uppercase"
                :color="gl.mainColor"
                :disabled="!canCreateBot"
                @click="onApplyClicked">
                {{ bots.localization['bot_create_button'] }}
            </n-button>
        </div>
    </div>
</section>
</template>

<script>
// general
import general from './general';

// icons
import { ArrowLeft12Regular, Warning24Filled, QuestionCircle20Filled, CalendarLtr32Regular } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input';
import ApiKeys from '@components/api-keys';
import BotApiKey from '@components/bots/childs/bot-api-key.vue';
import BotWallet from '@components/bot-wallet';
import BotPresets from '@components/bots/childs/bot-presets';
import RbSelect from '@components/rb-select';
import BotSettings from '@components/bots/childs/bot-settings';
import RbShoulder from '@components/rb-shoulder';
import RbCheckbox from '@components/rb-checkbox';
import RbPairs from '@components/rb-pairs/index.vue';
import Bots__running from '@/components/svg/Bots__running.vue';
import BotStopLoss from '@components/bots/childs/bot-stop-loss';
import BotPositionmode from '@components/bots/childs/bot-positionmode';
import BotStartFilters from '@components/bots/childs/bot-start-filters';
import BotNotifications from '@components/bots/childs/bot-notifications';
import BotAutoInversion from '@components/bots/childs/bot-auto-inversion';
import BotWalletCommissions from '@components/bots/childs/bot-wallet-commissions';
import BotSwitchTradingPair from '@components/bots/childs/bot-switch-trading-pair';
import BotAdditionalSettings from '@components/bots/childs/bot-additional-settings';
import BotExtendedSettings from '@components/bots/childs/bot-extended-settings';
import RbWalletSelectV2 from '@components/rb-wallet-select-v2';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NInput,
    NSelect,
    NButton,
    NDivider,
    NPopover,
    NCheckbox,
    NCollapse,
    NDatePicker,
    NCollapseItem } from 'naive-ui';

export default {
    name: 'bots-create-desktop',
    props: {
        gridAction: String,
    },
    components: {
        NIcon,
        NCard,
        NAlert,
        NInput,
        RbInput,
        NButton,
        NSelect,
        RbPairs,
        ApiKeys,
        RbSelect,
        NPopover,
        NDivider,
        BotWallet,
        NCheckbox,
        BotApiKey,
        NCollapse,
        RbShoulder,
        BotPresets,
        RbCheckbox,
        BotStopLoss,
        BotSettings,
        NDatePicker,
        NCollapseItem,
        Bots__running,
        BotStartFilters,
        RbWalletSelectV2,
        BotPositionmode,
        Warning24Filled,
        BotAutoInversion,
        BotNotifications,
        CalendarLtr32Regular,
        ArrowLeft12Regular,
        BotExtendedSettings,
        BotSwitchTradingPair,
        BotWalletCommissions,
        BotAdditionalSettings,
        QuestionCircle20Filled,
    },
    setup(props) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss">
@import '@/css/stock-icon.scss';

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>